<template>
  <div>
    <b-button
      class="service-edit-header-button-privacy"
      variant="outline-purple"
    >
      <div class="d-flex flex-nowrap">
        <feather-icon
          class="mr-25"
          :icon="isPublic ? 'UnlockIcon' : 'LockIcon'"
          size="14"
        />
        <p class="button-label m-0">
          {{ isPublic ? $t("integrator.pages.edit_service.header.privacy_toggle.public") : $t("integrator.pages.edit_service.header.privacy_toggle.private") }}
        </p>
      </div>
    </b-button>
    <b-button 
      id="edit-button"
      class="service-edit-header-button-privacy-drop"
      variant="outline-purple"
      @click="popoverOpen = true"
    >
      <b-spinner
        v-if="is_saving"
        small
        variant="purple"
      />
      <feather-icon
        v-else
        icon="EditIcon"
        size="16"
      />
    </b-button>

    <b-popover
      triggers="focus"
      variant="purple"
      offset="110"
      target="edit-button"
      :title="$t('integrator.pages.edit_service.header.privacy_toggle.privacy')"
      placement="bottom"
      :show.sync="popoverOpen"
    >
      <b-container fluid class="p-0">
        <b-row class="mb-1">
          <b-col cols="12">
            <p class="m-0 p-0" v-if="isPublic">
              {{$t("integrator.pages.edit_service.header.privacy_toggle.make_private")[0]}}
              <b>{{$t("integrator.pages.edit_service.header.privacy_toggle.make_private")[1]}}</b>?
            </p>
            <p class="m-0 p-0" v-else>
              {{$t("integrator.pages.edit_service.header.privacy_toggle.make_public")[0]}}
              <b>{{$t("integrator.pages.edit_service.header.privacy_toggle.make_public")[1]}}</b>?
            </p>
          </b-col>
        </b-row>
        <b-row style="width:270px">
          <b-col cols="12" class="d-flex justify-content-between">
            <b-button @click="closePopover" size="sm" variant="outline-purple">
              {{$t("integrator.pages.edit_service.header.cancel")}}
            </b-button>
            <b-button @click="submit" size="sm" variant="purple">
              {{$t("integrator.pages.edit_service.header.confirm")}}
            </b-button>
          </b-col>
        </b-row>
      </b-container>
    </b-popover>
  </div>
</template>

<script>
import { 
  BButton,
  BPopover,
  BContainer,
  BRow,
  BCol,
  BSpinner
} from 'bootstrap-vue'
import { mapGetters } from 'vuex'
import { makeToast } from '@/layouts/components/Popups'
  export default {
    components: {
      BButton,
      BPopover,
      BContainer,
      BRow,
      BCol,
      BSpinner
    },
    props: {
      serviceID: {
        type: [ Number, String ],
        required: true
      },
      orgID: {
        type: [ Number, String ],
        required: true
      },
    },
    computed: {
      ...mapGetters('serviceModule', ['getIntegratorServiceByID']),
      service() {
        return this.getIntegratorServiceByID(this.orgID, this.serviceID) 
      },
      isPublic() {
        return this.service.public
      }
    },
    data() {
      return {
        popoverOpen: false,
        is_saving: false
      }
    },
    methods: {
      closePopover() {
        this.popoverOpen = false
      },
      submit() {
        this.is_saving = true
        this.$store.dispatch('serviceModule/patchIntegratorService', { serviceID: this.serviceID, orgID: this.orgID, field: 'public', new_value: !this.isPublic } ).then(() => {
          this.popoverOpen = false
          this.is_saving = false
          this.$emit('saved')
          makeToast({
            title: '',
            text: '',
            variant: 'success',
            icon: 'CheckIcon'
          })
        }).catch((error) => {
          this.is_saving = false
          makeToast({
            title: '',
            text: '',
            variant: 'danger',
            icon: 'XIcon'
          })
        })
      }
    },
  }
</script>

<style lang="scss" scoped>

.service-edit-header-button-privacy {
  border-bottom-right-radius: 0;
  border-top-right-radius: 0;
  padding: 10px 15px 9px 15px;
  .button-label {
    font-weight: 500;
    font-size: 14px;
    line-height: 17px;
    text-align: center;
    letter-spacing: 0.4px;
  }
}
.service-edit-header-button-privacy-drop {
  padding: 10px;
  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

</style>