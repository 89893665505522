export default class Methods {
  constructor() {
      this.items = [
          {
              id: 1,
              label: "Header",
          },
          {
              id: 2,
              label: "Query",
          },
          {
              id: 3,
              label: "Body",
          },
          {
              id: 4,
              label: "Response Body",
          },
          {
              id: 5,
              label: "Response Header",
          },
          {
              id: 6,
              label: "Response Query",
          },
      ];
  }
}