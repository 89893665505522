<template>
  <b-popover
    :id="uuid"
    triggers="focus"
    variant="purple"
    offset="110"
    :title="$t('Edit Name')"
    :target="target"
    placement="righttop"
    :show.sync="popoverOpen"
  >
    <b-container fluid class="p-0 mb-0">
      <b-row class="mb-0 py-0">
        <b-col cols="12" class="m-0 px-50 py-0">
          <validation-provider
            #default="{ errors }"
            :name="i18nKey"
            :rules="rules"
          >
            <b-form-input
              :id="`${i18nKey}-input`"
              class="input-class"
              :ref="`${i18nKey}-input`"
              v-model="string"
              :state="errors.length > 0 ? false : null"
              :placeholder="`${i18nKey}`"
              @input="$emit('changed')"
              @keydown.enter="saveChange"
            />
            <p class="text-danger description-under-desc">{{ errors[0] }}</p>
          </validation-provider>
        </b-col>
      </b-row>
      <b-row>
        <b-col class="d-flex justify-content-end pr-50">
          <b-button
            class="px-50 py-25"
            variant="purple"
            @click="saveChange"
          >
            Confirmar
          </b-button>
        </b-col>
      </b-row>
    </b-container>
  </b-popover>
</template>

<script>
import {
  BPopover,
  BContainer,
  BRow,
  BCol,
  BButton,
  BFormInput,
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import validations from '@/utils/validations';
import { makeToast } from '@/layouts/components/Popups';

  export default {
    components: {
      BPopover,
      BContainer,
      BRow,
      BCol,
      BButton,
      ValidationProvider,
      ValidationObserver,
      BFormInput
    },
    props: {
      uuid: {
        type: String,
        default: '' 
      },
      value: {
        type: String,
        required: true
      },
      i18nKey: {
        type: String,
        required: true
      },
      target: {
        type: String,
        required: true
      },
      patchAction: {
        type: String,
        required: true
      },
      validator: {
        type: [ String, Array ],
        default: ''
      },
      dataForAction: {
        type: Object,
        default: () => new Object()
      }
    },
    data() {
      return {
        ...validations,
        popoverOpen: false,
        is_saving: false,
        original_value: null
      }
    },
    watch: {
      popoverOpen() {
        if (this.popoverOpen) {
          this.$refs[`${this.i18nKey}-input`].select()
          this.setOriginalValue()
        }
      }

    },  
    computed: {
      string: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      },
      rules() {
        if (this.validator instanceof Array) {
          return this.validator.join('|')
        }
        return this.validator 
      }
    },
    mounted () {
    },
    methods: {
      setOriginalValue() {
        this.original_value = this.string;
      },  
      saveChange() {
        if (this.string) {
          this.$store.dispatch(this.patchAction, { new_value: this.value, field: this.i18nKey, ...this.dataForAction }).then(() => {
            
            this.$emit('saved')
            this.setIsSaving(false)
            this.popoverOpen = false

            makeToast({
              title: `Success!`,
              text: `Field updated successfully`,
              variant: 'success',
              icon: 'CheckIcon'
            })
          }).catch(() => {

            this.setIsSaving(false)
            this.setOriginalValue()

            makeToast({
              title: 'Error',
              text: `Field coudn't be updated`,
              variant: 'danger',
              icon: 'XIcon'
            })
          })
        }
      },
      setIsSaving(state) {
        this.is_saving = state;
        this.$emit('isSaving', state);
      },
      closePopover() {
        this.popoverOpen = false
        this.string = this.original_value
      }
    },
  }
</script>

<style lang="scss" scoped>

</style>

<!-- <style lang="scss">
.dark-popover {
  background-color: #000 !important;
  .popover-body {
    background-color: #1f2436 !important;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
  }
}
</style> -->