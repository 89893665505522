<template>
  <b-card
    class="integrator-service-general-info-card"
    header-class="general-card-header px-0"
  >
			<!-- NEW RELEASE MODAL =============== -->
			<b-modal
				id="modal-create-release"
				ref="modal-create-release"
				centered
				:title="$t('integrator.service.edit.releases.header.new_release_modal.title')"
        @hide="((e) =>{ if (activeOverlays.includes('modal-create-release')) e.preventDefault()})"
			>
        <b-form-group class="p-0 m-0">
          <div class="p-50">
            <span class="mb-25 d-block">{{$t('integrator.service.edit.releases.header.new_release_modal.input_label')}}</span>
            <b-overlay :show="activeOverlays.includes('modal-create-release')" blur="none" variant="none">
              <template #overlay>
                <div/>
              </template>
              <b-input-group >
                  <b-form-input
                    id="middleware-name"
                    type="text"
                    v-model="newReleaseName"
                    autofocus
                  />
                  <b-input-group-append 
                    is-text 
                    class="cursor-pointer"
                    @click="generateReleaseName"
                  >
                    <b-icon
                      icon="arrow-repeat"
                      scale="1.1"
                      variant="info"
                      ref="newNameIcon"
                      :class=" spinIconState ? 'icon-spinning' : ''"
                    />
                  </b-input-group-append>
              </b-input-group>
            </b-overlay>
          </div>
        </b-form-group>
        <template #modal-footer>
          <b-button variant="purple" @click="insertNewRelease()" v-if="!activeOverlays.includes('modal-create-release')">
            {{$t('integrator.service.edit.releases.header.new_release_modal.confirm')}}
          </b-button>
          <b-button variant="purple" disabled v-else>
              <b-spinner small class="m-0 p-0"/>
          </b-button>
        </template>
      </b-modal>
		<!-- ======================== -->


		<!-- VIEW SCRIPT MODAL ==================== -->
		<b-modal
			ref="script-modal"
			hide-footer
			size="lg"
			centered
			:title="modalData.title"
			@shown="modalData.active = true;"			
			style="height: 500px;"
			class="p-0"
		>	
		<div style="height: 500px !important;">
			<div v-if="modalData.active" class="editor">
				<monaco-editor
					language="json"
					height="500px"
					@destroyed="modalData.active = false; modalData.skeleton_on = true"
					disableEditing
				
					v-model="modalData.script">
				</monaco-editor>
			</div>

			<div :class="modalData.skeleton_on ? 'px-2 py-1 editor-skeleton' : 'px-2 py-1 editor-skeleton inv'">
				<b-skeleton class="mb-1" :width="getRandomDecimal()+'5%'" :key="'skeleton_key_'+i"  v-for="i in 15" height="13px"/>
			</div>
		</div>
		</b-modal>
		<!-- ======================================== -->


		<!-- DELETE RELEASE MODAL ==================== -->
		<b-modal
			ref="delete-release-modal"
      id="delete-release-modal"
			size="md"
			centered
			:title="$t('integrator.service.edit.releases.table.delete_modal.title')"
			style="height: 500px;"
			class="p-0"
      @hide="((e) =>{ if (activeOverlays.includes('delete-release-modal')) e.preventDefault()})"
		>	
		<div class="text-censter mt-1">
			<p>{{$t('integrator.service.edit.releases.table.delete_modal.description')}}<b>{{modalData.title}}</b>?</p>
		</div>
    <template #modal-footer>

        <b-button :disabled="activeOverlays.includes('delete-release-modal')" variant="outline-secondary" class="mr-1" @click="$bvModal.hide('delete-release-modal')">Cancel</b-button>
       
        <b-button  v-if="!activeOverlays.includes('delete-release-modal')" variant="danger" @click="deleteRelease()">Confirm</b-button>
        <b-button v-else variant="danger" disabled>
          <b-spinner small class="m-0 p-0"/>
        </b-button>

    </template>
		</b-modal>
		<!-- ======================================== -->


    <template #header>
      <p class="my-0 ml-2 title text-light">
        {{$t('integrator.service.edit.releases.title')}}
      </p>
      <hr class="w-100"/>
    </template>
	
    <b-container fluid class="px-0">
				
				<div class="float-left d-flex" >		
					<span class="mt-75">{{$t('integrator.service.edit.releases.header.show')}}</span>
					<b-form-select class="num-select mx-50" :options="table.num_select_opts" v-model="table.rowsPerPage">	</b-form-select>	
				</div>

				<div align="right">
					
					<div class="al">
						<span class="mt-75 mx-1">{{$t('integrator.service.edit.releases.header.search')}}</span>
						<b-form-input type="search" v-model="table.tableSearchFilter" class="w-15 d-inline" :placeholder="$t('integrator.service.edit.releases.header.search_release')"></b-form-input>

						<b-button class="x-1 my-0 ml-1 custom-purple" @click="openModalCreate">
							
							<feather-icon
								icon="PlusCircleIcon"
								size="14"
							/>
							{{$t('integrator.service.edit.releases.header.new')}}
						</b-button>
					</div>
				</div>

			<div class="releases-table mt-1 mb-75 " v-if="releases">

				<!-- TABLE ============================= -->
				<b-table
					:items="releases"
					:fields="table.fields"
					:sort-by.sync="table.sortBy"
					:filter="table.tableSearchFilter" 
					:per-page="table.rowsPerPage"
					:current-page="table.currentPage"
					:sort-desc.sync="table.sortDesc"
					sort-icon-left
					@row-clicked="openModalScript"
					class="clickable"
				>
					<template #cell(enum_release_tag)="data">
            <b-popover
              :target="'release-popover'+data.item.id"
							:ref="'popover-'+data.index"
              triggers="focus"
              placement="left"
						  variant="purple"
							custom-class="integrator-release-patch-popover"
            >
              <template #title>
                <small><b>{{$t('integrator.service.edit.releases.table.tag_modal.title')}}</b></small>
              </template>
								<div class="d-flex justify-content-center">
                  <b-overlay :show="activeOverlays.includes('change-tag-release-'+data.index)" rounded opacity="1" variant="none">
                    <b-button v-for="tag in getAllTags" :key="'tag_key_'+tag.id"  :class="data.value==tag.id ?'px-50 py-25 ' :  'px-50 py-25 custom-faded'" variant="none" @click="changeTagRelease(tag.id , data.item, data.index)">
                      <b-badge v-if="data.value==tag.id" pill :variant="tag.variant.replace('light-','')" class="badge-glow" >{{$t(tag.label)}}</b-badge>
                      <b-badge  v-else pill :variant="tag.variant" >{{$t(tag.label)}}</b-badge>	
                    </b-button>
                  </b-overlay>
								</div>
            </b-popover>
            <b-button class="px-50 py-25" variant="none"  :id="'release-popover'+data.item.id" v-if="data.value.id">
						  <b-badge  pill :variant="(getTag(data.value.id))[0]">{{(getTag(data.value.id))[1]}}
              
              </b-badge>
              <feather-icon
                class="mx-50 text mb-25"                
                icon="EditIcon"
                size="16"
              />
            </b-button>
					</template>

					<template #cell(created_at)="data" >
						{{getDate(data.value)}}
					</template>

					<template #cell(actions)="data">
						<span class="px-50 py-25" variant="none">
							<feather-icon
								size="20"
								icon="EyeIcon"
								class="mr-50 btn-outline-purple clickable"
							/>
						</span>
						<b-button class="px-50 py-25" variant="none" @click="openDeleteModal(data.item)">
							<feather-icon
								size="20"
								icon="Trash2Icon"
								class="ml-50 btn-outline-purple clickable"
								style=""
							/>
						</b-button>
					</template>
				</b-table>
				
			<!-- =================================== -->
			</div>
      <div class="mt-1" v-if="!releases" >
        <b-skeleton-table 
          :rows="10"
          :columns="4"
        />
      </div>
			
			<div class="d-flex justify-content-between px-25 mt-0" v-if="releases">
				<span class="text-secondary"><small>{{$t('integrator.service.edit.releases.footer.table_info.0')}} {{((table.currentPage-1) * (table.rowsPerPage))+1}} {{$t('integrator.service.edit.releases.footer.table_info.1')}} {{table.currentPage*table.rowsPerPage >releases.length ? releases.length : table.currentPage*table.rowsPerPage}} {{$t('integrator.service.edit.releases.footer.table_info.2')}} {{releases.length}} {{$t('integrator.service.edit.releases.footer.table_info.3')}}</small></span>
				<b-pagination 
					v-model="table.currentPage"  
					:total-rows="releases.length" 
					:per-page="table.rowsPerPage"
					prev-class=""
     			next-class=""
					first-number
					last-number
					page-class="bg-variant-dark-blue"
					class="mx-1 customPagination pagination-purple"
					align="right"
					:limit="3"
				>
				
					<template  #page="{ page, active }">
						<span v-if="active" class="text-darker">{{page}}</span>
						<span v-else class="text-darker"><small>{{page}}</small></span>
					</template>
					<template #prev-text>
						<feather-icon
							icon="ChevronLeftIcon"
							size="18"
							class="text-purple"
						/>
					</template>
					<template #next-text>
						<feather-icon
							icon="ChevronRightIcon"
							size="18"
							class="text-purple"
						/>
					</template>
				</b-pagination>
			</div>
    </b-container>
  </b-card>
</template>

<script>
import {
  BCard,
  BContainer,
  BRow,
  BCol,
  BFormInput,
  BInputGroup,
  BFormGroup,
  BInputGroupAppend,
  BButton,
  BFormTextarea,
	BTable,
	BBadge,
	BSkeleton,
	BPagination,
	BFormSelect,
  BPopover,
	BSkeletonTable,
  BOverlay,
  BSpinner,
} from 'bootstrap-vue'
import 'easymde/dist/easymde.min.css'
import { ValidationProvider, ValidationObserver } from "vee-validate";
import ViewEditInput from '@/layouts/components/ViewEditInput.vue'
import EnumReleaseTags from '@/custom/class/Enum/ReleaseTags';
import monacoEditor from '@/layouts/components/editor/monaco.vue';
import { mapGetters } from 'vuex';
import {makeToast} from "@/layouts/components/Popups.js";
import axios from 'axios';

  export default {
    components: {
      BCard,
      BContainer,
      BRow,
      BCol,
      BFormInput,
      BInputGroup,
      BFormGroup,
      BInputGroupAppend,
      BButton,
      BFormTextarea,
      ValidationProvider,
      ValidationObserver,
      ViewEditInput,
			BTable,
			BBadge,
			BSkeleton,
			monacoEditor,
			BPagination,
			BFormSelect,
      BPopover,
			BSkeletonTable,
      BOverlay,
      BSpinner,
	},
	props: {
		serviceID: {
			type: [Number, String],
			required: true,
		},
	},
	data() {
		return {
      activeOverlays: [],
			table:{
				sortBy: 'created_at',
				sortDesc: true,
				tableSearchFilter: '',
				rowsPerPage: 10,
				currentPage: 1,
				num_select_opts: [
						{ value: 10, text: '10' , default:true},
						{ value: 25, text: '25' },
						{ value: 50, text: '50' },
						{ value: 100, text: '100' },
						{ value: 500, text: '500' }
					],
				fields: [
          { key: 'label', label:this.$t('integrator.service.edit.releases.table.labels.name'), sortable: true,},
          { key: 'enum_release_tag',label:this.$t('integrator.service.edit.releases.table.labels.tag'), sortable: true},
          { key: 'created_at',label:this.$t('integrator.service.edit.releases.table.labels.created'), sortable: true},
          { key: 'actions', label:this.$t('integrator.service.edit.releases.table.labels.actions') , sortable: false, class:'text-center',thStyle: "width: 150px; max-width: 150px;"}
        ],
			},
			newReleaseName: '',
			spinIconState:false,
			modalData :{
				index: null,
				itemID: null,
				active: false,
				skeleton_on: true,
				title: '',
				script : '',
			},
		}
	},
	computed: {
		...mapGetters('releaseModule', ['getReleases','getReleaseInfo']),
		releases() {
			 return this.getReleases(this.serviceID)
		},
		getAllTags(){
			return (new EnumReleaseTags).items
		}
	},
	mounted () {
		this.init();
	},
	methods: {
		init(){
			this.getReleasesByService();
		},
		getReleasesByService(){
			if (!this.releases) {
				this.$store.dispatch('releaseModule/getReleasesByService', {serviceID: this.serviceID}).then(() =>{
				}).catch((err)=>{
					console.error(err)
				});
			}
		},
		insertNewRelease(){
      const flag = 'modal-create-release';
      this.activeOverlays.push(flag)
			let data = {
  	 	 "integrator_service": this.serviceID,
  	  	"label": this.newReleaseName
			}
			this.$store.dispatch('releaseModule/insertRelease', {serviceID: this.serviceID , data: data}).then(() =>{
        makeToast({
          title: this.$t("agent.toast.create.success.title"),
          text: this.$t('integrator.service.edit.releases.toasts.insert_release.success.text.0') + this.newReleaseName + this.$t('integrator.service.edit.releases.toasts.insert_release.success.text.1'),
          variant: "success",
          icon: "CheckIcon",
        });
			}). catch((err) => {
				console.error(err);
        makeToast({
          title: this.$t("agent.toast.create.error.title"),
          text: this.$t('integrator.service.edit.releases.toasts.insert_release.error.text'),
          variant: "danger",
          icon: "XIcon",
        });
			}).finally(()=>{
        this.activeOverlays.splice( this.activeOverlays.indexOf(flag), 1);
        this.$bvModal.hide('modal-create-release');
      })
		},
		deleteRelease(){
      const flag = 'delete-release-modal'
      this.activeOverlays.push(flag);
			this.$store.dispatch('releaseModule/deleteRelease', {serviceID: this.serviceID, releaseID:this.modalData.itemID}).then(() =>{
          makeToast({
            title: this.$t("agent.toast.create.success.title"),
            text: this.$t('integrator.service.edit.releases.toasts.delete_release.success.text.0')+ this.modalData.title + this.$t('integrator.service.edit.releases.toasts.delete_release.success.text.1'),
            variant: "success",
            icon: "CheckIcon",
          });
				}).catch((err)=>{
					console.error(err)
          let errCode = err.data.code
          let errMsg = err.data.message.error
          //if this release is installed by any Org it cannot be deleted.
          if (errCode == 1451 && errMsg == "Cannot delete or update a parent row: a foreign key constraint fails (`fiqon`.`integrator_library`, CONSTRAINT `integrator_library_fk0` FOREIGN KEY (`ilib_fk_isrel_id`) REFERENCES `integrator_service_release` (`isrel_id`))"){
              makeToast({
              title: this.$t('integrator.service.edit.releases.toasts.cant_delete_installed.title'),
              text: this.$t('integrator.service.edit.releases.toasts.cant_delete_installed.text'),
              variant: "danger",
              icon: "XIcon",
            });  
            return
          }
          makeToast({
            title: this.$t("agent.toast.create.error.title"),
            text: this.$t('integrator.service.edit.releases.toasts.delete_release.error.text'),
            variant: "danger",
            icon: "XIcon",
           });
          return
				}).finally(()=>{
          this.activeOverlays.splice( this.activeOverlays.indexOf(flag), 1);
          this.$bvModal.hide('delete-release-modal');
        })
		},
		changeTagRelease(newTagId, release, rowIndex){
      const flag = 'change-tag-release-'+rowIndex
      this.activeOverlays.push(flag);
			this.$store.dispatch('releaseModule/updateReleaseTag', {serviceID: this.serviceID, releaseID:release.id, newTagId: newTagId}).then(() =>{
          makeToast({
            title: this.$t("agent.toast.create.success.title"),
            text: this.$t('integrator.service.edit.releases.toasts.change_tag.success.text.0') + this.modalData.title + this.$t('integrator.service.edit.releases.toasts.change_tag.success.text.1')+ this.getTag(newTagId)[1],
            variant: "success",
            icon: "CheckIcon",
            });      
          this.$refs['popover-'+rowIndex].$emit('close') //closes popover

				}).catch((err)=>{
					console.error(err)
          makeToast({
            title: this.$t("agent.toast.create.error.title"),
            text: this.$t('integrator.service.edit.releases.toasts.change_tag.error.text'),
            variant: "danger",
            icon: "XIcon",
           });
          return
				}).finally(()=>{
          this.activeOverlays.splice( this.activeOverlays.indexOf(flag), 1);
        })
		},
	

		getRandomDecimal(){
			let r = Math.floor(Math.random()*10)
			return r
		},
		newRelease() {
			let name = this.newReleaseName
		},
		getDate(initial_date){
			let date = initial_date.split(' ')[0];
			let month = date[5]+date[6];
			month = parseInt(month)
			month = this.$t("enum.date_months."+ month)
			month = month[0]+month[1]+month[2]
			let day = date[8]+date[9]
			let year = date[0]+date[1]+date[2]+date[3] 
			return day + " "+ month + ". " + year
		},
		getTag(tagId){
			let tagEnum = new EnumReleaseTags();
			tagEnum = tagEnum.items
      let r = tagEnum.find(el => el.id == tagId)
      return [r.variant , this.$t(r.label)]
		},
		openModalScript(item,index,event){		
      this.modalData.skeleton_on = true;
      this.modalData.index = index;
      this.modalData.title = item.label;
      this.$refs['script-modal'].show();
      
      this.$store.dispatch('releaseModule/getReleaseByID', {releaseID: item.id}).then(() =>{
        item.script = this.getReleaseInfo(item.id).script
        this.modalData.script = JSON.stringify(JSON.parse(item.script),null,4) //esses (...,null,4) dao identação pro JSON parseado
        this.modalData.skeleton_on = false;

      }).catch((err)=>{
        console.error(err);
      });
		},
		openDeleteModal(release){
			this.modalData.title = release.label
			this.modalData.itemID = release.id
			
			this.$refs['delete-release-modal'].show()
		},
		openModalCreate(){
			this.generateReleaseName()

			this.$refs['modal-create-release'].show()
		},
		generateReleaseName(){
			this.spinIconState = true
			setTimeout(() => {
				this.spinIconState = false
			}, 400);
			this.$store.dispatch('customNameGenerator').then((response) => {
				this.newReleaseName = response
			});
		},
	},
}

</script>

<style lang="scss">


.integrator-release-patch-popover{
	width: fit-content;
	max-width: none;
	box-shadow: 0 0 15px 2px rgba(0, 0, 0, 0.4) !important;
	background-color: #283046 !important;

	.popover-body{
		border-radius: 0px  0 5px  5px !important;
	}
	
}
</style>


<style lang="scss" scoped>


.num-select{
	width: 5%;
	min-width: 80px;
}

.w-15{
	width: 15%;
}
.integrator-service-general-info-card {
  .general-card-header {
    padding-top: 15px;
   .title {
      font-style: normal;
      font-weight: 500;
      font-size: 18px;
      line-height: 22px;
    }
  }
}
.description-under-desc {
  font-size: 10px !important;
}

.custom-purple {
  background-color: #7367f0 !important;
  border-color: #7367f0 !important;
}
.custom-purple:hover{
	  box-shadow: 0 4px 18px -4px #7367f0 !important;
}

.releases-table{
	outline: 1px solid #3b4253;
	overflow: hidden;
	border-radius: 5px !important;
}
.clickable{
	cursor: pointer;
}

.editor{
	box-shadow: 0 0 20px #1e1e1e;
}
.editor-skeleton{
	position: absolute !important;
	height: 500px;
	margin: 0 3%;
	top: 1em;
	left: 0;
	bottom: 1em;
	width: 94%;
	box-shadow: 0 0 20px #1e1e1e;
	background-color: #1e1e1e;
	
}
.editor-skeleton.inv{
	visibility: hidden;	
}
.icon-spinning{
	animation-name: iconSpin;
  animation-duration: 400ms;
  animation-iteration-count: 0.5;
  animation-timing-function: linear;
}

.rounded{
	border-radius: 300px !important;
	overflow: hidden !important;
}

@keyframes iconSpin {
	from {
		transform:rotate(0deg);
  }
	to {
		transform:rotate(360deg);
	}
}

</style>