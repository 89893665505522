<template>
  <b-form-group
    :label="i18nKey"
    :label-for="`${i18nKey}-input`"
    class="mb-0 input-form-group"
  >
    <validation-provider
      #default="{ errors }"
      :name="i18nKey"
      :rules="rules"
    >
      <b-input-group
        @click="inputClicked"
      >
        <b-form-input
          :id="`${i18nKey}-input`"
          class="input-class"
          :ref="`${i18nKey}-input`"
          v-model="string"
          :disabled="!is_editing"
          :state="errors.length > 0 ? false : null"
          :placeholder="`${i18nKey}`"
          @input="$emit('changed')"
          @keydown.enter="editInput"
        />
        <b-input-group-append
          is-text
          :class="errors.length > 0 ? 'input-append-state-error' :  ''"
          class="cursor-pointer input-group-append-edit"
          @click="editInput"
        >
          <b-spinner
            v-if="is_saving"
            variant="info"
            small
          />
          <feather-icon
            v-else
            :class="[ is_editing ? 'text-success' : '', shake_button ? 'animate__animated animate__headShake animate_faster' : '' ]"
            size="18"
            :icon="is_editing ? 'CheckIcon' : 'EditIcon'"
          />
        </b-input-group-append>
      </b-input-group>
      <p class="text-danger description-under-desc">{{ errors[0] }}</p>
    </validation-provider>
  </b-form-group>
</template>

<script>
import {
  BFormInput,
  BFormGroup,
  BInputGroup,
  BInputGroupAppend,
  BSpinner
} from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import validations from '@/utils/validations';
import { makeToast } from '@/layouts/components/Popups';
import 'animate.css';

  export default {
    components: {
      BFormInput,
      BFormGroup,
      BInputGroup,
      BInputGroupAppend,
      ValidationProvider,
      ValidationObserver,
      BSpinner,
    },
    props: {
      value: {
        type: [ String, Number ],
        required: true
      },
      i18nKey: {
        type: String,
        required: true
      },
      patchAction: {
        type: String,
        required: true
      },
      validator: {
        type: [ String, Array ],
        default: ''
      },
      dataForAction: {
        type: Object,
        default: () => new Object()
      }
    },
    data() {
      return {
        ...validations,
        is_editing: false,
        shake_button: false,
        is_saving: false
      }
    },
    computed: {
      string: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      },
      rules() {
        if (this.validator instanceof Array) {
          return this.validator.join('|')
        }
        return this.validator 
      }

    },
    methods: {
      editInput() {
        if (this.is_editing) {
          // this.setIsSaving(true)
          // this.saveChange()
        } else {
          this.setIsEditing(true)
          this.$nextTick(() => {
            this.$refs[`${this.i18nKey}-input`].select()
          })
        }
      },
      saveChange() {
        this.$store.dispatch(this.patchAction, { new_value: this.string, field: this.i18nKey, ...this.dataForAction }).then(() => {
          
          this.setIsEditing(false)
          this.$emit('saved')

          this.setIsSaving(false)

          makeToast({
            title: `${this.i18nKey}`,
            text: `${this.i18nKey}`,
            variant: 'success',
            icon: 'CheckIcon'
          })
        }).catch(() => {
          
          this.setIsEditing(false)
          this.setIsSaving(false)

          makeToast({
            title: `${this.i18nKey}`,
            text: `${this.i18nKey}`,
            variant: 'danger',
            icon: 'XIcon'
          })
        })
      },
      setIsSaving(state) {
        this.is_saving = state;
        this.$emit('isSaving', state);
      },
      setIsEditing(state) {
        this.is_editing = state
      },
      inputClicked() {
        if (!this.is_editing) {
          this.warnUserOfEditButton()
        }
      },
      warnUserOfEditButton() {
        this.shake_button = true
        setTimeout(() => {
          this.shake_button = false
        }, 1000)
      },
      generalUpdated() {
        this.setIsEditing(false)
      }
    },
  }
</script>

<style lang="scss" scoped>

.input-group-append-edit {
  > .input-group-text {
    &:hover {
      opacity: 0.6;
      // background-color: #fff !important;
    }
  }
}
.input-append-state-error {
    > .input-group-text {
      border-color: #ea5455 !important;
    }
  }

.input-form-group {
  .input-class {
    border-right: none;
  }
}

.description-under-desc {
  font-size: 10px !important;
}
</style>