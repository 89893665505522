export default class EndpointSetupType {
  constructor() {
    this.items = [
      {
        id: 1,
        type: 'request',
        namespace: 'requestModule/',
        addAction: 'createRequest',
        deleteAction: 'deleteRequest',
        patchAction: 'patchRequest',
        groupGetter: 'getGroupRequestByServiceID',
        requestGetter: 'getGroupRequest',
        getGroups: 'getIntegratorGroupRequestByServiceID',
        getItems: 'getRequestItems',
        addItem: 'addRequestItem',
        deleteItem: 'deleteRequestItem',
        patchItem: 'patchRequestItem'
      },
      {
        id: 2,
        type: 'webhook',
        direction: 'received',
        namespace: 'webhookModule/',
        addAction: 'createWebhook',
        deleteAction: 'deleteWebhook',
        patchAction: 'patchWebhook',
        groupGetter: 'getGroupWebhookByServiceID',
        requestGetter: 'getGroupWebhook',
        getGroups: 'getIntegratorGroupWebhookByServiceID',
        getItems: 'getWebhookItems',
        addItem: 'addWebhookItem',
        deleteItem: 'deleteWebhookItem',
        patchItem: 'patchWebhookItem'
      }
    ];
  }
}