<template>
  <div>
    <div class="d-flex m-50 integrator-folder-nav" v-if="!isLoadingGroups">
      <b-form-input
        class="mr-50"
        :placeholder="$t('Filter')"
        v-model="search"
        @input="filter"
      />
      <b-dropdown
        no-caret
        variant="outline-purple"
        toggle-class="px-1 integrator-folder-nav-dropdown"
        right
        menu-class="bg-light"
      >
        <template #button-content>
          <feather-icon
            icon="PlusCircleIcon"
          />
        </template>
        <b-dropdown-item
          v-for="(item, index) in options"
          :key="item.label"
          class="integrator-folder-nav-dropdown-item"
          @click="item.action(item, index)"
        >
          <feather-icon
            class="mr-50"
            :icon="item.icon"
            size="20"
          />
          {{ $t(item.label) }}
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="d-flex m-50 pb-0" v-else>
      <b-skeleton
        animation="throb"
        class="mr-50 mb-0"
        height="38px"
        width="80%"
      />
      <b-skeleton
        class="mb-0"
        animation="throb"
        type="button"
        height="38px"
        width="20%"
      />
    </div>
    <vue-perfect-scrollbar style="height: 560px;">
      <folder-groups
        ref="folder-group-component"
        :serviceID="serviceID"
        :enumSetupType="enumSetupType"
        @setSelected="(item) => $emit('setSelected', item)"
        @isMounted="isLoadingGroups = false"
      />
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import {
  BFormInput,
  BButton,
  BDropdown,
  BDropdownItem,
  BSkeleton,
} from 'bootstrap-vue'
import FolderGroups from '@/views/pages/integrator/components/EditService/Endpoint/FolderGroup'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

  export default {
    components: {
      BFormInput,
      BButton,
      FolderGroups,
      BDropdown,
      BDropdownItem,
      BSkeleton,
      VuePerfectScrollbar
    },
    props: {
      serviceID: {
        type: [ Number, String ],
        required: true,
      },
      enumSetupType: {
        type: [ String, Number ],
        required: true
      }
    },
    data() {
      return {
        isLoadingGroups: true,
        search: '',
        options: [
          { label: this.$t('integrator.pages.edit_service.tabs.operations.new_folder'), icon: 'FolderPlusIcon', action: this.addGroup },
          { label: this.$t('integrator.pages.edit_service.tabs.operations.new_request'), icon: 'PlusCircleIcon', action: this.addRequest},
        ],
      }
    },
    methods: {
      filter(string) {
        this.$refs["folder-group-component"].filterRequest(string)
      },
      addGroup() {
        this.$refs['folder-group-component'].addGroup()
      },
      addRequest() {
        this.$refs['folder-group-component'].addRequest()
      }
    },
  }
</script>

<style lang="scss">
.integrator-folder-nav {
  .integrator-folder-nav-dropdown-item {
    > .dropdown-item {
      padding: 7px 10px;
      color: #6E6B7B !important;
      &:hover, &:active, &:focus {
        color: #7367F0 !important;
        background: #7367f01f !important;
      }
    }
  }
}
</style>