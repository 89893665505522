import DataType from "@/custom/class/Enum/DataType.js"
import FieldType from "@/custom/class/Enum/FieldType.js"

export default class RequestField {
  constructor(
    {
      id = null,
      enum_data_type = 1,
      enum_field_type,
      example_value,
      field_key,
      note = "", 
      required = true,
      created_at = null,
      updated_at = null,
    }
  ){
    this.id = id;
    this.enum_data_type = new DataType().items.find(el => el.id == enum_data_type)
    this.enum_field_type = new FieldType().items.find(el => el.id == enum_field_type)
    this.example_value = example_value
    this.field_key = field_key
    this.note = note
    this.required = required
    this.created_at = created_at
    this.updated_at = updated_at
  }
}