<template>
  <b-container fluid class="management-wrapper">
    <b-row class="management-body">
      <b-col class="border rounded-left p-0 border-right-0" md="2">
        <navigation
          :key="`${enumSetupType}-navigation`"
          :serviceID="serviceID"
          :enumSetupType="enumSetupType"
          @setSelected="setSelected"
        />
      </b-col>
      <b-col :cols="isWebhook ? 8 : 5" class="border">
        <sent-mapper
          style="max-height: 600px"
          :key="`${enumSetupType}-sent-mapper-${requestID}`"
          :serviceID="serviceID"
          :groupID="groupID"
          :requestID="requestID"
          :enumSetupType="enumSetupType"
          @mapReceived="mapReceived"
        />
      </b-col>
      <b-col :cols="isWebhook ? 2 : 5" class="border rounded-right border-left-0">
        <received-wrapper
          style="max-height: 600px"
          :ref="`${enumSetupType}-received-mapper-${requestID}`"
          :key="`${enumSetupType}-received-mapper-${requestID}`"
          :serviceID="serviceID"
          :groupID="groupID"
          :requestID="requestID"
          :enumSetupType="enumSetupType"
        />
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { 
  BContainer,
  BRow,
  BCol,
  BCard
} from 'bootstrap-vue'
import Navigation from '@/views/pages/integrator/components/EditService/Endpoint/Navigation.vue'
import SentMapper from '@/views/pages/integrator/components/EditService/Endpoint/MapperWrapper.vue'
import ReceivedWrapper from '@/views/pages/integrator/components/EditService/Endpoint/ReceivedWrapper.vue'

export default {
    components: {
      BContainer,
      BRow,
      BCol,
      BCard,
      Navigation,
      SentMapper,
      ReceivedWrapper,
    },
    props: {
      serviceID: {
        type: [ Number, String ],
        required: true,
      },
      enumSetupType: {
        type: [ String, Number ],
        required: true
      }
    },
    data() {
      return {
        requestID: undefined,
        groupID: undefined
      }
    },
    computed: {
      isWebhook() {
        return this.enumSetupType == 2
      }
    },
    methods: {
      setSelected(payload) {
        const { item, groupID } = payload
        this.requestID = item.id
        this.groupID = groupID
      },
      mapReceived(payload) {
        this.$refs[`${this.enumSetupType}-received-mapper-${this.requestID}`].mapReceived(payload)
      }
    },
  }
</script>

<style lang="scss" scoped>

.management-wrapper {
  .management-body {
    height: 615px; 
    background-color: #283046;
  }
}

</style>