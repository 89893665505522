<template>
  <div>
    <b-button v-if="bodyFromState != body" class="save-button py-25 px-50 mb-50" variant="purple" @click="emitSave">
      <div style="width: 35px">
        <span v-if="!isSaving">
          Save
        </span>
        <b-spinner v-else
          style="width: 0.8rem; height: 0.8rem"
        />
      </div>
    </b-button>
    <monaco
      v-if="bodyFromState != null"
      :language="language"
      height="450px"
      class="my-editor"
      v-model="body"
    />
  </div>
</template>

<script>
import { BButton, BSpinner } from 'bootstrap-vue'
import Monaco from '@/layouts/components/editor/monaco.vue';
  export default {
    components: {
      Monaco,
      BButton,
      BSpinner
    },
    props: {
      language: {
        type: [ String, Number ],
        default: 'raw'
      },
      value: {
        type: String,
        required: true,
      },
      bodyFromState: {
        type: String,
        required: true,
      }
    },
    data() {
      return {
        isSaving: false
      }
    },
    computed: {
      body: {
        get() {
          return this.value
        },
        set(value) {
          this.$emit('input', value)
        }
      }
    },
    methods: {
      emitSave() {
        if (this.isSaving) return
        
        this.$emit('save')
        this.setIsSaving(true)
      },
      setIsSaving(state) {
        this.isSaving = state
      }
    },
  }
</script>

<style lang="scss" scoped>
.save-button {
  position: absolute;
  z-index: 2;
  right: 3%;
  top: 120px;
}
</style>