<template>
  <b-container fluid class="p-0 service-info-mapper h-100">
    <div v-if="groupID && serviceID && !isWebhook">
      <b-row style="height: 50px" class="border-bottom justify-content-between service-info-mapper-header">
        <b-col cols="12" class="d-flex justify-content-end align-items-center">
          <b-badge 
            pill
            variant="success"
            class="font-weight-bolder"
          >
          {{ $t('integrator.pages.edit_service.tabs.operations.200ok') }}
          </b-badge>
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12" class="p-0 m-0">
          <div class="d-flex nav-items" style="height: 50px">
            <div
              v-for="(item, index) in navItems" :key="item.title"
              class="h-100 d-flex align-items-center justify-content-center cursor-pointer px-1 w-50 border-bottom"
              :class="active[index] ? 'text-light border border-bottom-0 border-top-0' : ''"
              @click="setActive(index)"
            >
              <span class="d-flex text-truncate text-capitalize">
                {{ $t(item.title) }}&nbsp;
                <span style="font-size: 10px">
                  {{ getTabCount(item) }}
                </span>
              </span>
              <b-dropdown
                v-if="item.options"
                no-caret
                variant="outline-none"
                toggle-class="p-25 btn-none border-0 dropdown-chevron"
                :offset="-78"
              >
                <template #button-content>
                  <feather-icon
                    size="17"
                    icon="ChevronDownIcon"
                  />
                </template>
                <b-dropdown-item v-for="navOptionItem in item.options" :key="navOptionItem.id" @click="item.action(navOptionItem)">
                  {{ $t(navOptionItem.label) }}
                </b-dropdown-item>
              </b-dropdown>
            </div>
            <hr class="w-100 position-relative mt-4"/>
          </div>
        </b-col>
      </b-row>
      <div
        v-if="active[0] && body != null"
      >
        <div v-if="bodyField === false" class="text-center mt-2 font-weight-bolder">{{ $t('integrator.pages.edit_service.tabs.operations.no_body') }}</div>
        <body-editor
          v-else-if="bodyField == 'schema'"
          :key="`${version}${direction}-mapper-body`"
          :ref="`${version}${direction}-mapper-body`"
          class="mt-1"
          :language="bodyType.value"
          v-model="body"
          :bodyFromState="bodyFromState"
          @save="patchBody"
        />
        <div
          class="w-100"
          v-else-if="bodyField == 'fields'"
        >
          <vue-perfect-scrollbar style="max-height: 500px;">
            <mapper
              class="mt-1"
              :ref="getID('request-mapper-body')"
              :key="getID('request-mapper-body')"
              :serviceID="serviceID"
              :groupID="groupID"
              :requestID="requestID"
              :field_type="body_received_field_type"
              enumSetupType="1"
              type="body"
              :direction="direction"
            />
          </vue-perfect-scrollbar>
        </div>
      </div>
      <div
        class="w-100"
        v-if="active[1]"
      >
        <vue-perfect-scrollbar style="max-height: 500px;">
          <mapper 
            class="mt-1"
            :ref="getID('request-mapper-headers')"
            :key="getID('request-mapper-headers')"
            :serviceID="serviceID"
            :groupID="groupID"
            :requestID="requestID"
            :field_type="header_received_field_type"
            enumSetupType="1"
            type="headers"
            :direction="direction"
          />
        </vue-perfect-scrollbar>
      </div>
    </div>
    <div v-else-if="isWebhook" class="h-100">
      <b-row style="height: 50px" class="border-bottom service-info-mapper-header">
        <b-col cols="12" class="d-flex align-items-center justify-content-end">
          <b-badge
            pill
            variant="light-purple"
          >
          {{ $t("integrator.pages.edit_service.tabs.operations.webhook") }}
          </b-badge>
        </b-col>
      </b-row>
      <b-row class="h-75">
        <b-col cols="12" class="d-flex justify-content-center placeholder-request h-100">
          <div class="w-75 d-flex flex-column text-center h-100 justify-content-center">
            <feather-icon
              icon="CoffeeIcon"
              size="70"
              class="alert-icon align-self-center my-2"
            />
            <span class="main-text my-1">
              {{ $t("integrator.pages.edit_service.tabs.operations.received_wrapper.no_map_webhook") }}
          </span>
            <span class="info-text">
              </span>
          </div>
        </b-col>
      </b-row>
    </div>
    <div v-else class="h-100">
      <b-row style="height: 50px" class="border-bottom service-info-mapper-header">
        <b-col cols="12" class="d-flex align-items-center justify-content-end">
          <b-badge
            pill
            variant="warning"
          >
          {{ $t("integrator.pages.edit_service.tabs.operations.not_found") }}
          </b-badge>
        </b-col>
      </b-row>
      <b-row class="h-75">
        <b-col cols="12" class="d-flex justify-content-center placeholder-request h-100">
          <div class="w-75 d-flex flex-column text-center h-100 justify-content-center">
            <feather-icon
              icon="MapIcon"
              size="70"
              class="alert-icon align-self-center my-2"
            />
            <span class="main-text my-1">
              {{ $t("integrator.pages.edit_service.tabs.operations.received_wrapper.description") }}
          </span>
            <span class="info-text">
              {{ $t("integrator.pages.edit_service.tabs.operations.received_wrapper.feel_free") }}
            </span>
          </div>
        </b-col>
      </b-row>
    </div>
  </b-container>
</template>

<script>
import {
  BContainer,
  BRow,
  BCol,
  BBadge,
  BFormInput,
  BButton,
  BNav,
  BNavItem,
  BDropdownItem,
  BNavItemDropdown,
  BDropdown
} from 'bootstrap-vue'
import BodyTypes from '@/custom/class/Enum/BodyTypes.js'
import Mapper from '@/views/pages/integrator/components/EditService/Endpoint/Mapper.vue'
import BodyEditor from '@/views/pages/integrator/components/EditService/Endpoint/BodyEditor.vue'
import Methods from '@/custom/class/Enum/Methods';
import { mapGetters } from 'vuex'
import RequestField from "@/views/pages/integrator/components/EditService/Endpoint/class/RequestField.js"
import { v4 as uuidv4 } from 'uuid'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

  export default {
    components: {
      BContainer,
      BRow,
      BCol,
      BBadge,
      BFormInput,
      BButton,
      BNav,
      BDropdownItem,
      BNavItemDropdown,
      BNavItem,
      BDropdown,
      Mapper,
      BodyEditor,
      VuePerfectScrollbar
    },
    props: {
      serviceID: {
        type: [ Number, String ],
        required: true
      },
      groupID: {
        type: [ Number, String ],
        default: null
      },
      requestID: {
        type: [ Number, String ],
        default: null
      },
      enumSetupType: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        navItems: null,
        active: [ true, false ],
        header_received_field_type: 5,
        query_received_field_type: 6,
        body_received_field_type: 4,
        direction: 'received',
        body: null,
        bodyType: null,
        version: 0,
        uuidMap: {},
        loadingInfo: new Object()
      }
    },
    computed: {
      ...mapGetters('requestModule', ['getGroupRequest']),
      request() {
        return this.getGroupRequest(this.serviceID, this.groupID, this.requestID)
      },
      enumBodyItems() {
        return new BodyTypes().items 
      },
      methods() {
        return new Methods().items
      },
      bodyField() {
        if (!this.bodyType) return

        switch (true) {
          case !!this.bodyType.has_fields: {
            return 'fields'
          }
          case !!this.bodyType.has_schema: {
            return 'schema'
          }
          default: {
            return false
          }
        } 
      },
      bodyFromState() {
        if (this.bodyField == 'schema') {
          return this.request.body[this.direction][this.bodyField]
        }
      },
      isWebhook() {
        return this.enumSetupType == 2
      }
    },
    mounted () {
      this.init();
    },
    methods: {
      init() {
        this.setActive(1)
        this.$nextTick(() => {
          this.initializeInfo()
        })
      },
      initializeInfo() {
        if (this.request && Object.keys(this.request).length) {
          this.setBody()
        }
      },
      initializeItems() {
        this.navItems = [
          {
            title: this.bodyType.label,
            options: this.enumBodyItems,
            action: this.setBodyType
          },
          {
            title: 'Headers',
            key: 'headers',
            direction: 'received'
          },
        ]
      },
      setBody() {
        let typeID = this.request.body[this.direction].type.id
        let bodyField = this.bodyField

        this.bodyType = this.getEnumBodyTypeByID(typeID) || false
        
        if (!this.body) {
          this.body = bodyField ? this.request.body[this.direction].schema : false
        }

        this.$nextTick(() => {
          this.initializeItems()
        })
      },
      setBodyType(item) {
        this.$store.dispatch('requestModule/patchBodyType',
          {
            serviceID: this.serviceID,
            groupID: this.groupID,
            requestID: this.requestID,
            direction: this.direction,
            new_value: item.id,
            field: 'enum_return_body_type'
          }).then((resp) => {
            this.$nextTick(() => {
              this.version++
              this.setBody()
            })
          }).catch((err) => {
            console.log(err)
          })
      },
      patchInfo(value, field) {
        if (this.request[field] && (value == this.request[field] || value == this.request[field].id)) return

        this.$store.dispatch('requestModule/patchRequest',
          {
            field: field,
            serviceID: this.serviceID,
            requestID: this.requestID,
            groupID: this.groupID,
            new_value: value
          }).then((resp) => {
          }).catch((err) => {
          })
      },
      patchBody() {
        this.$store.dispatch('requestModule/patchBody',
          {
            serviceID: this.serviceID,
            groupID: this.groupID,
            requestID: this.requestID,
            direction: this.direction,
            new_value: this.body,
            field: this.bodyField,
            request_field: 'return_body_schema'
          }).then((resp) => {
            this.initializeInfo()
          }).catch((err) => {
            this.initializeInfo()
          })
      },
      mapReceived(payload) {

        let data = JSON.parse(payload)
        let headers = data.headers

        let resp_body = data.data

        this.setResponseHeaders(headers)
        this.setResponseData(resp_body, headers["content-type"])
      },
      setResponseData(data, contentType) {
        contentType = contentType?.split(';') || 'raw'

        contentType = this.enumBodyItems.find((el) => el.tag == contentType[0])
  
        this.setBodyType(contentType)

        switch (contentType.id) {
          case 2: {
            let data_string = JSON.stringify(data, null, 4)
            this.$set(this.request.body.received, "schema", data_string)
            this.$nextTick(() => {
              this.setBody()
              this.$nextTick(() => {
                this.patchBody()
              })
            })
            this.version++

          } break;
          case 3: {
            var xmlString = data
            var domParser = new DOMParser();
            var dom = domParser.parseFromString(xmlString, 'text/xml');

            this.$set(this.request.body.received, "schema", dom)

            this.$nextTick(() => {
              this.setBody()
              this.$nextTick(() => {
                this.patchBody()
              })
            })
            this.version++
          } break;
          case 4: {
            this.setResponseBodyList(data)
            this.$nextTick(() => {
              this.setBody()
            })
          } break;
          case 5: {
            this.setResponseBodyList(data)
            this.$nextTick(() => {
              this.setBody()
            })
          } break;
        }
      },
      setResponseHeaders(headers) {
        if (!this.request.headers.received) {
          this.request.headers.received = new Array()
        }

        Object.keys(headers).forEach((key) => {
          if (!this.request.headers.received.find(el => key == el.field_key)) {
            let header_data = new RequestField({ enum_field_type: 5, field_key: key, example_value: headers[key] })
            this.$nextTick(() => {
              this.$refs[this.getID('request-mapper-headers')].addRow(header_data, 'headers')
            })
          }
        })
        
      },
      setResponseBodyList(body) {
        if (!this.request.body.received) {
          this.request.body.received = new Array()
        }

        Object.keys(body).forEach((key) => {
          if (!this.request.body.received.find(el => key == el.field_key)) {
            let body_data = new RequestField({ enum_field_type: 4, field_key: key, example_value: body[key] })
            this.$nextTick(() => {
              this.$refs[this.getID('request-mapper-body')].addRow(body_data, 'body')
            })
          }
        })
        
      },
      setActive(index) {
        this.active.forEach((_, key) => {
          this.$set(this.active, key, index == key)
        })
      },
      getMethod(enum_id, field) {
        return this.methods[enum_id -1][field]
      },
      getTabCount(item) {
        const { key, direction } = item
        if (key && this.request[key]) {
          let length = Math.max(this.request[key][direction].length - 1, 0)
          return length ? `(${length})` : ''
        }
        return
      },
      getEnumBodyTypeByID(id) {
        return this.enumBodyItems.find((el) => el.id == id)
      },
      getID(key) {
        if (this.uuidMap[key]) {
          return this.uuidMap[key];
        }

        const uuid = uuidv4();
        this.uuidMap[key] = uuid;

        return uuid;
      },
    }
  }
</script>

<style lang="scss">
.dropdown-chevron {
  color: #B4B7BD !important;
}

.request-method-option {
  > .dropdown-item {
    padding: 7px 10px;
    &:hover, &:active, &:focus {
      background: #343d55 !important;
    }
  }
}
</style>

<style lang="scss" scoped>

.service-info-mapper {
  .service-info-mapper-header {
    background-color: #343D55 !important;
  }
  .alert-icon {
    opacity: 0.35 !important;
  }
  .placeholder-request {
    .main-text {
      font-size: 16px;
      line-height: 20px;
      text-align: center;
      color: #B4B7BD;
    }
    .info-text {
      font-size: 14px;
      line-height: 20px;
      text-align: center;
      color: #B4B7BD;
    }
    .info-link {
      font-weight: 500;
      font-size: 14px;
      line-height: 17px;
      text-align: center;
      letter-spacing: 0.4px;
      color: #B4B7BD;
    }
  }
}
</style>